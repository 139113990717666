export const NOTIFY_GENERIC = 1
export const NOTIFY_ERROR = 2
export const NOTIFY_SUCCESS = 3

const notify = {
    add: function(type, text, duration) {
        const diff = 20

        type = type || NOTIFY_GENERIC
        text = text || "No text was provided for this notification!"
        duration = duration || 5

        const bodyHTML = `<div class="notify__body">${text}</div>`
        const positionTo = "bottom"

        let html = `
            <div class="notify no-overflow notify-%i">
                <div class="notify__header">
                    <div class="notify__icon notify-icon-%i"></div>
                    ${bodyHTML}
                </div>
            </div>
        `

        const replacementMap = {
            "%i": type
        }

        for (const [key, value] of Object.entries(replacementMap)) {
            html = html.replaceAll(key, value)
        }

        const body = $("body")
        const getNotificationList = () => $(".notify")

        let height = 90
        getNotificationList().each(function() {
            height = height + $(this).height() + diff
        })

        body.append(html)

        const notificationList = getNotificationList()
        const element = notificationList.last()
        const container = element.children(".notify__header")
        const initWidth = element.outerWidth(true)
        element.attr("element-id", notificationList.length - 1)
        element.css(positionTo, height)

        container.children(".notify__body").remove()

        setTimeout(() => {
            const slideAnimDur = 100
            element.animate({
                width: initWidth + "px"
            }, slideAnimDur, "swing")

            setTimeout(() => {
                container.append(bodyHTML)
            }, slideAnimDur)
        }, 250)

        setTimeout(() => {
            const animationDur = 0.25
            element.css("animation", `notify_leave ${animationDur}s ease`)
            setTimeout(() => {
                getNotificationList().each(function() {
                    if ($(this).is(element)) {
                        return
                    }
                    
                    const elementId = Number($(this).attr("element-id"))
                    if (elementId > Number(element.attr("element-id"))) {
                        let pos = Number($(this).css(positionTo).replace("px", ""))
                        $(this).css({
                            [positionTo]: pos - element.height() - diff
                        });

                        $(this).attr("element-id", elementId - 1)
                    }
                })

                element.remove()   
            }, animationDur * 1000 * 0.75)
        }, duration * 1000)
    },

    generic: function(text, duration) {
        this.add(NOTIFY_GENERIC, text, duration)
    },

    error: function(text, duration) {
        this.add(NOTIFY_ERROR, text, duration)
    },

    success: function(text, duration) {
        this.add(NOTIFY_SUCCESS, text, duration)
    }
}

export default notify;